/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from 'styled-components';
import { Input as ReInput } from '@rebass/forms/styled-components';

const Styles = {
  Input: styled(ReInput)(() => `
    border-color: #6c6c6c;
  `),
};

export const Input = (props) => <Styles.Input {...props} />;
