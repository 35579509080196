/* global fetch */
export const requestInitialState = {
  data: null,
  pending: false,
  error: false,
  lastError: null,
  lastSuccess: null,
};

export function getUrl() {
  // console.log(process.env.GATSBY_BACKEND_URL);
  // return process.env.GATSBY_BACKEND_URL;
  return 'https://1t8igzgxr4.execute-api.us-east-1.amazonaws.com/prod/rest';
}

export async function requestAccess(password) {
  const url = getUrl();
  const config = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
    }),
  };

  return fetch(url, config)
    .then((response) => response.json());
}
