import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from 'rebass/styled-components';
import styled from 'styled-components';

import { Input } from '../Base/Forms';
import { generateFormState } from './utils';
import { requestInitialState, requestAccess } from '../utils/requestAccess';

const Styles = {
  Form: styled(Box)``,
  Input: styled(Input)`
    margin: 1rem 0;
  `,
  Button: styled(Button)``,
};

const FORM_FIELDS = {
  password: 'password',
};

const initialFormState = generateFormState(Object.keys(FORM_FIELDS));

const LoginForm = ({ onSuccess, onError }) => {
  const [formState, setFormState] = useState(initialFormState);
  const [request, setRequest] = useState(requestInitialState);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setRequest({
      ...requestInitialState,
      pending: true,
    });

    const { value } = formState[FORM_FIELDS.password];

    try {
      const key = 'password';
      const data = await requestAccess(value);
      const { valid, token } = data;
      const errors = !valid ? ['Das Passwort ist ungültig'] : [];

      // update form
      setFormState((prev) => ({
        ...prev,
        [key]: {
          ...prev[key], // load previous state
          dirty: true,
          valid,
          errors,
        },
      }));

      // update request
      setRequest({
        ...requestInitialState,
        data,
        lastSuccess: Date.now(),
      });

      if (valid) {
        onSuccess({ valid, token }, event);
      } else {
        const error = new Error('Ungültiges Passwort');
        onError(error, event);
      }
    } catch (error) {
      // handle error
      setRequest({
        ...requestInitialState,
        error,
        lastError: Date.now(),
      });
      onError(error, event);
    }
  };

  const handleChange = (key) => (event) => {
    const { target: { value } } = event;

    setFormState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key], // load previus state
        value,
      },
    }));
  };

  const getFormField = (key, props = {}) => {
    const { value } = formState[key];
    // const error = errors.length > 0 ? errors[0] : '';

    return (
      <Styles.Input
        value={value}
        onChange={handleChange(key)}
        id={key}
        {...props}
      />
    );
  };

  const handleOnKeyUp = ({ keyCode }) => {
    if (keyCode === 13) {
      handleSubmit();
    }
  };

  const { pending } = request;

  return (
    <Styles.Form
      as="form"
      onSubmit={handleSubmit}
    >
      {getFormField(FORM_FIELDS.password, {
        type: 'password',
        disabled: pending,
        onKeyUp: handleOnKeyUp,
        placeholder: 'Passwort eingeben...',
      })}
      <Styles.Button
        type="submit"
        variant="primary"
        disabled={pending}
      >
        {pending ? 'Überprüfen' : 'Absenden'}
      </Styles.Button>
    </Styles.Form>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {};

export default LoginForm;
