import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Box, Heading } from 'rebass/styled-components';

import Page from '../components/Page/Page';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import LoginForm from '../components/LoginForm/LoginForm';
import Text from '../components/Base/Text';

import { isLoggedIn, login } from '../components/utils/auth';
import * as Paths from '../components/utils/Paths';

const Styles = {
  Content: styled(Box)`
    margin: 2rem auto;
    text-align: center;
    max-width: 24rem;
  `,
  Heading: styled(Heading)`
    margin: 1rem 0;
  `,
  Text: styled(Text)`
    margin: 1.5rem 0 3rem;
    hyphens: auto; 
    text-align: justify;
  `,
  LoginForm: styled(LoginForm)``,
  Error: styled(Box)``,
};

const IndexPage = () => {
  const [error, setError] = useState(null);

  // handle auth
  useEffect(() => {
    if (isLoggedIn()) {
      navigate(Paths.SECURED);
    }
  });

  const handleOnSuccess = ({ token }) => {
    login(token);
    navigate(Paths.SECURED);
  };

  const handleOnError = (formError) => {
    setError(formError.message);
  };

  return (
    <Page>
      <SEO title="Home" />
      <Layout>
        <Styles.Content>
          <Styles.Heading fontSize={[3, 4, 6]}>Willkommen bei liistro.ch</Styles.Heading>
          <Styles.Text>
            Bitte verifizieren Sie sich mittels Passwort um auf den Inhalt dieser Seite zuzugreifen.
          </Styles.Text>
          {error && (
            <Styles.Error>
              <Text fontSize={[1, 2, 3]} color="secondary">{error}</Text>
            </Styles.Error>
          )}
          <Styles.LoginForm
            onSuccess={handleOnSuccess}
            onError={handleOnError}
          />
        </Styles.Content>
      </Layout>
    </Page>
  );
};

export default IndexPage;
