export const generateFieldFormState = (initialState = {}) => ({
  value: '',
  valid: false,
  dirty: false,
  errors: [],
  ...initialState,
});

export const generateFormState = (fieldNames, initialState = {}) => fieldNames
  .reduce((prev, cur) => ({
    ...prev,
    [cur]: generateFieldFormState(),
  }), initialState);
